import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import TagManager from 'react-gtm-module';

const usePageTracking = (): void => {
  const location = useLocation();
  const isProduction = process.env.REACT_APP_ENVIRONMENT === 'prod';

  useEffect(() => {
    // Push a pageview event to GTM's data layer on route change
    if (isProduction) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'pageview',
          page: location.pathname + location.search,
        },
      });
    }
  }, [location]);
};

export default usePageTracking;
