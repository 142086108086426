import TagManager from 'react-gtm-module';

const initializeGTM = () => {
  // Ensure GTM ID exists and we're in the production environment before initializing
  const gtmId = process.env.REACT_APP_GTM_ID;
  const isProduction = process.env.REACT_APP_ENVIRONMENT === 'prod';

  if (gtmId && isProduction) {
    const tagManagerArgs = {
      gtmId: gtmId,
    };
    TagManager.initialize(tagManagerArgs);
  }
};

export default initializeGTM;
