import { Toaster } from 'react-hot-toast';
import { Outlet } from 'react-router-dom';
import { Loader } from './components/Loader/Loader';
import useStrapiData from './hooks/use-strapi-data';
import { SkipToMain } from './features/shared/components';
import useStrapiPublicSiteData from './hooks/use-strapi-publicsite-data';
import { ErrorBoundary } from 'react-error-boundary';
import { ApplicationErrorBoundary } from './components/ErrorBoundary';
import { Suspense, useEffect } from 'react';
import { registerSW } from './service-worker-registration';
import initializeGTM from './utils/gtm';
import usePageTracking from './utils/usePageTracking';

function App() {
  useEffect(() => {
    initializeGTM(); // Initialize GTM when the app mounts
  }, []);

  usePageTracking(); // Use the page tracking hook

  // getting data from strapi for login and registration during app mount
  const { loading: isStrapiDataLoading, error } = useStrapiData();
  const { loading: isPublicSiteLoading, error: publicSiteError } = useStrapiPublicSiteData();

  // so that we don't consume the bandwidth when the strapi query is loading
  useEffect(() => {
    if (isStrapiDataLoading === false && isPublicSiteLoading === false) {
      if ('serviceWorker' in navigator) {
        registerSW();
      }
    }
  }, [isStrapiDataLoading, isPublicSiteLoading]);

  if (isStrapiDataLoading || isPublicSiteLoading) {
    return <Loader />;
  }
  if (error) {
    console.error('Strapi Main page error', error);
  }
  if (publicSiteError) {
    console.error('Strapi Public site error', publicSiteError);
  }

  return (
    <ErrorBoundary FallbackComponent={ApplicationErrorBoundary} resetKeys={[location.pathname]}>
      <Suspense fallback={<Loader />}>
        {/* Skip To Main Content Link */}
        <SkipToMain />
        <Outlet />
      </Suspense>
      <Toaster position="bottom-right" gutter={5} />
      {/* Will be excluded from build when process.env.mode === "production" */}
      {/* Uncomment if required */}
      {/* <ReactQueryDevtools initialIsOpen={false} panelPosition="left" position="top-left" /> */}
    </ErrorBoundary>
  );
}

export default App;
